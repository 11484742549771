import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMRF } from '../redux/action'
import { stateActions } from '../redux/reducers';
import { getPresignedUrl } from '../redux/services';
import centivoLogo from '../assets/logos/centivo-logo-orange.svg';

const DISPLAY_URL = process.env.REACT_APP_DISPLAY_URL || 'files.centivo.dev';

class MRF extends Component {
    componentDidMount() {
        const { clientId } = this.props;
        if (!clientId) {
            this.props.setError(`Please provide a valid client ID as part of the URL (${DISPLAY_URL}/CLIENT)`);
        } else {
            this.props.getMRF(clientId);
        }
    } 

    handleDownloadClick = async (fileKey) => {
        // Unused since files are public
        try {
            const url = await getPresignedUrl(fileKey);
            window.location.replace(url);
        } catch (error) {
            this.props.setError(`Unable to fetch download URL`);
        }
    }

    render() {
        const { files, loading, error, clientId } = this.props;
        const displayClientId = clientId ? clientId.toUpperCase() : null;
        const headers = ['Name', 'Size', 'Last Modified (UTC)'];
        const headerKeys = ['name', 'size', 'lastModified'];

        if (loading) {
            return (
                <div>
                    <div className='banner'><img src={centivoLogo} alt='Centivo'/></div>
                    <div className='centered-notice'><h2>Loading...</h2></div>
                </div>
            );
        }

        if (error) {
            return (
                <div>
                    <div className='banner'><img src={centivoLogo} alt='Centivo'/></div>
                    <div className='centered-notice'><h2>{error}</h2></div>
                </div>
            );
        }

        return (
            <div>
                <div className='banner'><img src={centivoLogo} alt='Centivo'/></div>
                <div className='centered-content'>
                    <h2>{displayClientId}/</h2>
                    {files.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    {headers.map((header) => (
                                        <th key={header}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {files.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {headerKeys.map((headerKey) => (
                                            <td key={headerKey}>
                                                {headerKey === 'name' ? (
                                                    //<button onClick={() => this.handleDownloadClick(row.key)}>{row.name}</button> // If using API to retrieve file URL
                                                    <a href={row.url}>{row.name}</a>
                                                ) : (
                                                    row[headerKey]
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan='3'>{files.length} items</td>
                                </tr>
                            </tfoot>
                        </table>
                    ) : (
                        <p>No files available for this client.</p>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    files: state.files,
    loading: state.loading,
    error: state.error,
});

const mapDispatchToProps = (dispatch) => ({
    getMRF: (clientId) => dispatch(getMRF(clientId)),
    setError: (message) => dispatch({ type: stateActions.error, payload: message })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MRF);
